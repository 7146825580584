<template>
  <div>
    
    
    

    <div class="col-12">
      <div class="card mb-4">
        <div class="card-header">
          <h4 class="card-title">
            <span>{{RelName}}</span>
          </h4>
     

          <b-col v-if="showFiltro ? false : true ">
            <div style="float:right">
              <div class="DivBotaoLadoLado" >
              <b-button variant="danger" @click="$router.push('../Relatorios')">
                   <span>RETORNAR</span>
               </b-button>
              </div>
               
               <div class="DivBotaoLadoLado" >
               <b-button v-b-toggle="'collapse-filtro'" variant="primary" >
                 <span>FILTROS</span>
               </b-button>
               </div>
            </div>
          </b-col>

        </div>

        <b-collapse visible  id="collapse-filtro">
        <div class="card-body">
          
          <div class="x">
        <div class="y">
            <iframe title="TESTE URL"
                :src="this.RelRota"                 
                frameborder="0"
                allowFullScreen="true">
            </iframe>
            <div class="overlay"></div>
        </div>
    </div>
          </div>
        </b-collapse>
        
      </div>
    </div>

    



  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import VueApexCharts from 'vue-apexcharts'
import 'bootstrap-icons/font/bootstrap-icons.css'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import Spinner from '@/components/Spinner.vue'
import UtilVUE from '@/customPortal/UtilVUE.vue'


import {
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BTable,
  BFormSelect,
  BFormInput,
  BCollapse,
  BCard,
  VBToggle,
  BFormDatepicker,
} from 'bootstrap-vue'

export default {
  components: {
    VueApexCharts,
    Multiselect,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BTable,
    BFormSelect,
    BFormInput,
    BCollapse,
    BCard,
    BFormDatepicker,
  },
  directives: { 'b-toggle': VBToggle },
  data() {
    return {
      showFiltro: true,
      FiltroRelatorio: [],
      RelName: '',
      RelRota: ''      
    }
  },
  methods: {
  },
  computed: {
  },
  mounted() {
    const usuario = this.$session.get('usuario')

    this.RelRota = "https://app.powerbi.com/reportEmbed?reportId=ae93f65a-90b0-44d8-aa0c-eabd45d9fe62&autoAuth=true&ctid=c5c124c1-9756-4db8-8960-df686a8a5a53&filter=TabelaExemplo/CC eq '10101 - CONSELHO'";
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
        .x {
            position: relative;
            width: 100%;
        }
        .y {
            position: relative;
            width: 100%;
            height: 800px;
        }
        .y iframe {
            width: 100%;
            height: 100%;
        }
        .overlay {
            position: absolute;
            top: 0;
            right: 0;
            width: 22%;
            height: 100%;
            background-color: red;
            pointer-events: auto; /* Permitir eventos de clique */
        }
    </style>
